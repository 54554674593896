<template>
  <NuxtLink
    to="https://onderwijs.in"
    target="_blank"
    title="Ga naar Onderwijs in"
    aria-label="Ga naar Onderwijs in"
    class="block transition-colors"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583.4 111.64">
        <g>
          <path class="fill-[#FF9255]" d="M170.63,32.61a25.34,25.34,0,0,1,9.63,9.67,27.7,27.7,0,0,1,3.52,14,27.69,27.69,0,0,1-3.56,14A25.48,25.48,0,0,1,170.55,80a28,28,0,0,1-27.05,0,25.52,25.52,0,0,1-9.66-9.71,27.59,27.59,0,0,1-3.56-14,27.42,27.42,0,0,1,3.56-14,25.56,25.56,0,0,1,9.66-9.67,28.11,28.11,0,0,1,27.13,0ZM147,45.12q-3.71,4.1-3.71,11.13T147,67.34a14.16,14.16,0,0,0,20,0q3.75-4.11,3.75-11.09T167,45.16a14.26,14.26,0,0,0-20,0Z"/>
          <path class="fill-[#FF9255]" d="M227.05,45.46q4.24,4.8,4.24,13.19V82.93H218.47V60.15a9.19,9.19,0,0,0-2.21-6.56,7.68,7.68,0,0,0-5.88-2.36,8,8,0,0,0-6.18,2.47q-2.3,2.47-2.29,7V82.93H189.1V41.11h12.81v7.65a13.82,13.82,0,0,1,5.36-5.92,15.71,15.71,0,0,1,8.36-2.18Q222.82,40.66,227.05,45.46Z"/>
          <path class="fill-[#FF9255]" d="M262,42.76a12.66,12.66,0,0,1,5,5.92V27.48h12.82V82.93H267V75.36a12.61,12.61,0,0,1-5,5.92,15.14,15.14,0,0,1-8.24,2.17,17,17,0,0,1-9.26-2.58,17.7,17.7,0,0,1-6.44-7.46A25.89,25.89,0,0,1,235.71,62a25.88,25.88,0,0,1,2.36-11.43,17.68,17.68,0,0,1,6.44-7.41,16.91,16.91,0,0,1,9.26-2.59A15.14,15.14,0,0,1,262,42.76ZM251.26,54.53c-1.68,1.8-2.51,4.3-2.51,7.49s.83,5.7,2.51,7.5a9.42,9.42,0,0,0,13.26-.08A10.42,10.42,0,0,0,267.11,62a10.36,10.36,0,0,0-2.59-7.45,9.44,9.44,0,0,0-13.26,0Z"/>
          <path class="fill-[#FF9255]" d="M326.16,64.34h-29c.15,3.15.94,5.41,2.39,6.79a7.76,7.76,0,0,0,5.55,2.06,7.57,7.57,0,0,0,4.72-1.43,6.63,6.63,0,0,0,2.47-3.67h13.57a17.75,17.75,0,0,1-3.6,7.91,19.33,19.33,0,0,1-7,5.47,24.11,24.11,0,0,1-20.42-.6,18.48,18.48,0,0,1-7.46-7.46A23.12,23.12,0,0,1,284.64,62a23.43,23.43,0,0,1,2.66-11.43,18.17,18.17,0,0,1,7.46-7.41,24.57,24.57,0,0,1,21.88,0A18,18,0,0,1,324,50.29a21.37,21.37,0,0,1,2.58,10.61A17.49,17.49,0,0,1,326.16,64.34Zm-14.8-11.57a8.12,8.12,0,0,0-5.66-2.06,8.44,8.44,0,0,0-5.85,2.09A8.79,8.79,0,0,0,297.23,59h16.34Q313.65,54.83,311.36,52.77Z"/>
          <path class="fill-[#FF9255]" d="M350.32,42.91a15.77,15.77,0,0,1,8.21-2.25V54.38h-3.67q-5.1,0-7.91,2.17t-2.81,7.34v19H331.33V41.11h12.81V49A18.21,18.21,0,0,1,350.32,42.91Z"/>
          <path class="fill-[#FF9255]" d="M426.19,41.11,415.48,82.93H401.17l-7.57-28-7.65,28H371.64L360.92,41.11h12.82l5.54,30.13,7.8-30.13h13.64l8,30.2,5.55-30.2Z"/>
          <path class="fill-[#FF9255]" d="M431.36,24.52a9,9,0,0,1,11.09,0,6.52,6.52,0,0,1,0,9.59,9,9,0,0,1-11.09,0,6.17,6.17,0,0,1-2.09-4.76A6.23,6.23,0,0,1,431.36,24.52Zm11.92,16.59V82.93H430.46V41.11Z"/>
          <path class="fill-[#FF9255]" d="M441.93,91.85h3.15A4.11,4.11,0,0,0,448,91a3.85,3.85,0,0,0,.9-2.88v-47h12.89V88q0,14.91-15,14.91h-4.87Zm7.79-57.74a6.52,6.52,0,0,1,0-9.59,9,9,0,0,1,11.09,0,6.52,6.52,0,0,1,0,9.59,9,9,0,0,1-11.09,0Z"/>
          <path class="fill-[#FF9255]" d="M496.52,44.56a17.29,17.29,0,0,1,6,10.49h-12a6.68,6.68,0,0,0-2.36-4,7.2,7.2,0,0,0-4.61-1.46,5,5,0,0,0-3.3.94,3.26,3.26,0,0,0-1.12,2.66,3.11,3.11,0,0,0,2.06,2.92,32.67,32.67,0,0,0,6.48,1.95,63.39,63.39,0,0,1,7.87,2.29,12.91,12.91,0,0,1,5.32,3.78,10.46,10.46,0,0,1,2.25,7,10.77,10.77,0,0,1-1.95,6.37,12.88,12.88,0,0,1-5.62,4.35,22,22,0,0,1-8.7,1.57q-8.46,0-13.56-3.74A15.81,15.81,0,0,1,467,69H479.4a5.57,5.57,0,0,0,2.28,4.12,8.6,8.6,0,0,0,5.13,1.43,4.76,4.76,0,0,0,3.3-1,3.4,3.4,0,0,0,1.13-2.66,3.22,3.22,0,0,0-2.06-3.11,38.59,38.59,0,0,0-6.71-2,53.12,53.12,0,0,1-7.64-2.1A12.67,12.67,0,0,1,469.65,60a10.19,10.19,0,0,1-2.17-6.86,11.22,11.22,0,0,1,4.27-9.11q4.28-3.48,12.07-3.48T496.52,44.56Z"/>
          <path class="fill-[#FF9255]" d="M522.33,24.52a9,9,0,0,1,11.09,0,6.52,6.52,0,0,1,0,9.59,9,9,0,0,1-11.09,0,6.17,6.17,0,0,1-2.09-4.76A6.23,6.23,0,0,1,522.33,24.52Zm11.92,16.59V82.93H521.43V41.11Z"/>
          <path class="fill-[#FF9255]" d="M579.17,45.46q4.23,4.8,4.23,13.19V82.93H570.59V60.15a9.23,9.23,0,0,0-2.21-6.56,7.69,7.69,0,0,0-5.88-2.36,8,8,0,0,0-6.18,2.47q-2.3,2.47-2.29,7V82.93H541.22V41.11H554v7.65a13.76,13.76,0,0,1,5.36-5.92,15.7,15.7,0,0,1,8.35-2.18Q574.94,40.66,579.17,45.46Z"/>
          <rect class="fill-[#FF9255]" width="111.64" height="111.64" rx="30.97" transform="translate(0 111.64) rotate(-90)"/>
          <path class="fill-white" d="M55.83,94.09a38.51,38.51,0,0,1-7.31-.7l2.15-11.14A26.93,26.93,0,0,0,82.25,50.67l11.14-2.15A38.28,38.28,0,0,1,55.83,94.09Z"/>
          <path class="fill-white" d="M18.25,63.12A38.28,38.28,0,0,1,63.12,18.25L61,29.39A26.93,26.93,0,0,0,29.39,61Z"/>
          <circle class="fill-white" cx="79.15" cy="32.19" r="7.87"/>
          <circle class="fill-white" cx="32.84" cy="78.97" r="7.87"/>
        </g>
    </svg>
  </NuxtLink>
</template>