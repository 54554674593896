
<template>
  <NuxtLink
    to="/"
    title="Terug naar homepage"
    aria-label="Terug naar homepage"
    class="block transition-colors logo"
  >
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 665 183.11">
      <g id="logoContent" >
          <path class="cls-1" d="M622,116l14.99,36,14.76-36h13.25v65.5h-13l.99-40-10.23,26.03c-2.19-.43-12.04.67-13.03-.53l-8.99-25.51.75,40.01h-13v-65.5h13.5Z"/>
          <polygon class="cls-1" points="453 116 453 128.5 432.5 128.5 432.5 142.5 451 142.5 451 154.5 432.5 154.5 432.5 169 454 169 454 181.5 419 181.5 419 116 453 116"/>
          <polygon class="cls-1" points="412 116 412 128.5 399.5 128.5 399.5 181.5 385.5 181.5 385.5 128.5 372.5 128.5 372.5 116 412 116"/>
          <path class="cls-1" d="M368.5,116v12.5h-12.25c-.05,0-.75.7-.75.75v52.25h-13.5v-52.25c0-.05-.7-.75-.75-.75h-12.25v-12.5h39.5Z"/>
          <path class="cls-3" d="M385.51,80l5.46-27.28c1.23-.25,5.72-.62,6.31.25l5.21,27.03,5.23-28.03c.67-.99,4.93-.25,6.28-.47-2.92,11.59-4.71,23.91-7.83,35.41-.16.6-.37,1.95-.61,2.3-.49.74-5.3.07-6.55.29l-4.76-25-5.25,25-6.43-.06-8.56-37.93c1.35.22,5.61-.52,6.28.47l5.23,28.03Z"/>
          <path class="cls-3" d="M263,51.5c1.62.23,4.57-.57,5.76.49l11.24,24.01v-24.5h5.5v38l-5.92-.08-10.83-24.92.25,25.01h-6v-38Z"/>
          <path class="cls-3" d="M534.5,51.5v15c2.51-2.03,7.64-13.62,9.5-14.75,1.29-.77,4.86.03,6.49-.25.18.75-.18,1.17-.47,1.78-1.19,2.49-9.81,13.76-9.87,14.97l12.34,21.24c-1.83-.29-6,.64-7.26-.49l-8.25-15.51c-.3-.23-2.49,3.04-2.49,3.25v12.75h-6v-37.25c0-1.19,4.93-.65,6-.75Z"/>
          <path class="cls-3" d="M469,62c-.98-.17-5.72.24-5.98-.02-.09-.09-.03-1.39-.26-1.97-2.98-7.46-14.35-1.19-5.72,5.7,4.27,3.4,11.23,5.16,12.34,11.66,2.76,16.21-22.39,17.8-22.38,1.64,1.06.2,5.53-.31,5.95.05.64.55-.17,4.23,3.53,5.21,3.45.91,7.08-.56,7.03-4.52-.07-6.39-14.2-8.49-15.49-16.52-2.56-15.92,21.38-16.49,20.97-1.22Z"/>
          <polygon class="cls-3" points="342 51.5 342 57.5 329 57.5 329 67.5 340.5 67.5 340.5 73 329 73 329 84 342.5 84 342.5 89.5 323 89.5 323 51.5 342 51.5"/>
          <polygon class="cls-3" points="575.5 51.5 575.5 57.5 563 57.5 563 67.5 574.5 67.5 574.5 73 563 73 563 84 576 84 576 89.5 557 89.5 557 51.5 575.5 51.5"/>
          <polygon class="cls-3" points="602 51.5 602 57.5 594 57.5 594 89.5 588 89.5 588 57.5 580 57.5 580 51.5 602 51.5"/>
          <polygon class="cls-3" points="481.5 51.5 481.5 84 493.5 84 493.5 89.5 475.5 89.5 475.5 51.5 481.5 51.5"/>
          <path class="cls-2" d="M441,51.5v35.75c0,.17-1.07,2.77-1.3,3.2-2.25,4.32-6.22,5.03-10.7,5.55.21-1.14-.44-5.07.28-5.56,1.62-1.07,5.02-.07,5.68-4.22l.1-33.9c.5-1.52,4.57-.57,5.93-.82Z"/>
          <rect class="cls-3" x="419" y="51.5" width="6" height="38"/>
          <path class="cls-3" d="M128.05,97.7c-.23-.91.06-.58.58-.81,1.85-.81,4.45-1.21,6.5-2.02C184.96,75.19,173.07,4.03,120.74,0H0s0,181.99,0,181.99h176.5l-48.45-84.3ZM11,11h107.75c26.73,1.28,44.56,27.82,34,52.99-6.65,15.84-23.53,24.41-40.4,22.91l-31.35-55.4H24.5l20.5,36.5H11V11ZM11,114.5h34l-20.5,36.5h55.75l31.5-55.5,45.75,75.5H11v-56.5Z"/>
          <path class="cls-4" d="M75,143h-35.5l21-36.5H12.5v-30.5h48l-21-37h34.75c10.62,16.58,19.73,34.19,29.99,51.03l.27,1.17-29.51,51.79Z"/>
          <path class="cls-1" d="M263.58,157.15c-.02-.55,3.75-3.55,4.63-4.69,6.44-8.4,5.62-25.76-2.96-32.46-1.79-1.4-7.36-4-9.5-4h-23.25v65.5h13.5v-22c1.29.18,3.93-.5,4.75.5l10.5,21.5h14.25l-11.92-24.35ZM258.52,139.27c-.64,7.81-5.86,8.13-12.52,7.73v-18.5c8.5-.84,13.28,1.61,12.52,10.77Z"/>
          <path class="cls-1" d="M494.49,157.23c.02-.66,3.85-3.59,4.72-4.76,6.13-8.19,5.58-25.06-2.48-31.95-1.88-1.61-7.63-4.51-9.99-4.51h-23.25v65.5h13.5v-22c1.06.21,3.72-.36,4.42.08,1.02.64,8.83,19.25,10.08,21.92h15l-12.01-24.27ZM477,147v-18.5c7.39-.45,12.19.31,12.52,8.73.35,8.79-4.64,10.54-12.52,9.77Z"/>
          <path class="cls-1" d="M322.03,128.22c-3.61-11.28-14.2-14.58-25.15-13.09-10.11,1.37-15.68,9.98-16.4,19.6-.6,8.04-.88,29.73,1.93,36.61,7.31,17.88,38.84,15.28,40.61-7.07.55-6.91.84-30.31-.99-36.04ZM308.94,164.19c-2.22,8.21-13.24,7.57-14.4-.99-.78-5.74-.69-22.57-.04-28.46.17-1.55,1.08-3.98,2.17-5.08,2.73-2.76,9.16-2.32,11.25,1.16.76,1.26,1.36,4,1.51,5.49.54,5.24.74,23.33-.49,27.88Z"/>
          <path class="cls-1" d="M552.52,135.73c-.65-8.8-6.87-19.73-16.77-19.73h-22.25v65.5h23.25c5.58,0,12.53-7.14,14.27-12.23,2.31-6.76,2.06-25.9,1.5-33.54ZM538.02,164.77c-1.98,5.18-6.94,4.2-11.52,4.23v-39.75c0-1.2,5.39-.76,6.53-.53,4.4.89,5.64,4.43,5.99,8.51.44,5.1.59,23.41-.99,27.54Z"/>
          <path class="cls-1" d="M586.27,116.48c-.8-1.14-12.28-.11-14.47-.51l-15.81,65.53h14c.68-2.72.78-7.49,1.85-9.89.29-.64.13-.56.88-.64,4.12-.44,9.05.39,13.26.04.42,3.43.84,7.47,2.25,10.5h13.25l-15.23-65.02ZM574.5,158l4.25-25.01,4.25,25.01h-8.5Z"/>
          <path class="cls-3" d="M364.01,75.24c6.22-2.77,7.68-10.3,5.85-16.35-1.45-4.81-5.31-6.88-10.11-7.39-2.98-.32-6.88,0-10,0-.05,0-.75.7-.75.75v37.25h6v-13.5c1.1.05,2.4-.33,3.26.49,1.72,2.12,5.35,11.38,6.95,12.55,1.46,1.06,4.94.19,6.79.46l-7.99-14.25ZM355,70.5v-13c5.5-.34,9.54-.45,9.54,6.25,0,6.32-4.06,7.28-9.54,6.75Z"/>
          <path class="cls-3" d="M314.26,57.49c-1.62-3.67-5.68-5.59-9.51-5.99-2.99-.31-6.88,0-10,0-.05,0-.75.7-.75.75v37.25h12.75c3.05,0,6.88-3.84,7.9-6.6,1.52-4.14,1.4-21.36-.39-25.4ZM309.53,78.28c-.56,5.77-4.6,6.07-9.53,5.72v-26.5c4.88-.14,8.85-.63,9.5,5.25.41,3.63.38,11.85.02,15.53Z"/>
          <path class="cls-3" d="M254.75,58.5c-1.88-5.76-7.57-8.31-13.38-7.38-3.99.63-7.05,3.39-8.39,7.11-1.63,4.56-1.61,21.31.27,25.77,3.58,8.5,17.39,8.43,20.99,0,1.79-4.19,1.92-21.14.5-25.49ZM249.28,79.53c-1.28,7.33-10.39,6.94-11.27-1.78-.36-3.58-.48-16.07,1.26-18.73.52-.79,1.65-1.69,2.59-1.91,4.89-1.12,7.18,1.6,7.64,6.15.37,3.73.41,12.69-.22,16.27Z"/>
          <path class="cls-3" d="M521,60.75c-.79-6.97-6.98-10.56-13.62-9.62-4.38.62-7.99,3.61-9.04,7.96-.87,3.64-.75,17.05-.34,21.15,1.22,12.13,18.4,13.63,22.23,3.24,1.21-3.29,1.22-18.75.77-22.74ZM514.88,80.13c-1.08,6-9.93,5.97-10.84-.43-.5-3.49-.48-14.39,0-17.91.26-1.89,1.26-4.22,3.31-4.69,4.11-.93,7.02.38,7.6,4.69.45,3.33.5,15.15-.07,18.34Z"/>
      </g>
    </svg>
  </NuxtLink>
</template>